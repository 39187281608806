import React from 'react';
import { useMsal } from '@azure/msal-react';
import { createContext, useCallback, useContext } from 'react';
import { useAuth } from '../auth/AccountProvider';
import PropTypes from 'prop-types';
import { useB2CConfigs } from '../services/ApiConfigsContextProvider';
import { isJsonResponse } from '../utils/isJsonResponse';
import { isPdfResponse } from '../utils/isPdfResponse';
import useLogout from '../auth/useLogout';

export function useFetcher() {
  const user = useAuth();
  const { instance } = useMsal();
  const { scopes } = useB2CConfigs();
  const logout = useLogout();

  return useCallback(
    /**
     *
     * @param { string } url
     * @param { RequestInit } options
     * @returns
     */
    async (url, options = {}) => {
      const { headers = {}, body, ...other } = options;
      const bodyStringify = body ? JSON.stringify(body) : null;
      const token = user && !isExpired(user.idTokenClaims.exp) ? await instance.acquireTokenSilent({ account: user, scopes }) : '';
      const result = await fetch(url, {
        method: options.method || 'GET',
        headers: new Headers({
          Authorization: `Bearer ${token.accessToken}`,
          'Content-Type': 'application/json',
          ...headers,
        }),
        body: bodyStringify,
        ...other,
      });
      let response;
      if (isJsonResponse(result)) {
        response = await result.json();
      } else if (isPdfResponse(result)) {
        response = await result.blob();
      } else {
        response = await result.text();
      }
      if (result.status === 401) {
        logout();
        return;
      }
      if (result.status >= 400) {
        throw { status: result.status, message: response };
      }
      return response;
    },
    [instance, scopes, user, logout],
  );
}

const isExpired = expTime => Date.now() >= expTime * 1000;

const FetcherContext = createContext(null);
export const useQueryFetcher = () => useContext(FetcherContext);
export function FetcherProvider({ children }) {
  const fetcher = useFetcher();
  return <FetcherContext.Provider value={fetcher}>{children}</FetcherContext.Provider>;
}

FetcherProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
