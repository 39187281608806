import { useMsal } from '@azure/msal-react';
import { useCallback } from 'react';
import { useAuth } from './AccountProvider';

export default function useLogout() {
  const user = useAuth();
  const { instance } = useMsal();

  return useCallback(
    () => instance.logout({ account: user, postLogoutRedirectUri: window.location.href }),
    [instance, user],
  );
}
