import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from 'react-query';
import { withApiConfigs } from '../services/ApiConfigsContextProvider';
import LoadingSuspense from '../components/LoadingSuspense';
import { useHistory } from 'react-router-dom';
import { isDev } from '../utils/isDev';

function AuthorizedContext({ children, webApiUrl }) {
  const { isLoading, data, isError, error } = useQuery(`${webApiUrl}/api/users/authorize`);
  const history = useHistory();

  if (isLoading) return <LoadingSuspense />;
  if (isError || !data) {
    if (error.status === 401 || error.status === 403) {
      if (isDev) {
        console.error(error.message);
      }
      history.push('/unauthorized');
    } else {
      history.push('/404');
    }
    return null;
  }
  return children;
}

AuthorizedContext.propTypes = {
  children: PropTypes.node.isRequired,
  webApiUrl: PropTypes.string.isRequired,
};

export default withApiConfigs(AuthorizedContext);
