import React, { createContext, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useMsal } from '@azure/msal-react';

const AccountContext = createContext(null);
/**
 *
 * @returns {(import('@azure/msal-browser').AccountInfo) | null}
 */
export const useAuth = () => useContext(AccountContext);

function AccountProvider({ children }) {
  const { accounts } = useMsal();

  return (
    <AccountContext.Provider value={useMemo(() => accounts[0] || null, [accounts])}>
      {children}
    </AccountContext.Provider>
  );
}

AccountProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AccountProvider;
