import React from 'react';
import PropTypes from 'prop-types';
import { useAuth } from './AccountProvider';
import RedirectingToLogin from './RedirectingToLogin';

function AuthenticatedContext({ children }) {
  const user = useAuth();

  return user ? children : <RedirectingToLogin />;
}

AuthenticatedContext.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthenticatedContext;
